import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BlogPostList from '../components/blog-post-list'
import IndexTop from '../components/index-top'
import EmailSubscription from '../components/email-subscription'
import Footer from '../components/footer'
import Divider from '../components/divider'
// import Icon from 'svg-react-loader?name=Icon!../static/svgs/logo-activity-0-designspectrum.svg';




class IndexPage extends Component {
  // componentDidMount() {
  //   // set up and use external package as needed
  //   window.externalLibrary.method()
  // }

  render() {
    const banner = this.props.data.allContentfulAsset.edges[0].node
    const illustMain = this.props.data.allContentfulAsset.edges[1].node

    return (
      <div>
        <Helmet
          meta={[
            { name: 'title', content: '디자인 스펙트럼' },
            { name: 'keyword, ', content: '디자인스펙트럼, 디자인팟캐스트, 디자인행사, 디자이너, 개발자, 스타트업, 디자인모임, 디자인스터디' },
            { name: 'description', content: '사람과 사람을 연결하고, 지식과 경험을 연결하는 지속가능한 디자인 커뮤니티' },
            { property: 'og:title', content: '디자인 스펙트럼' },
            { property: 'og:description', content: '사람과 사람을 연결하고, 지식과 경험을 연결하는 지속가능한 디자인 커뮤니티' },
            { property: 'og:image', content: 'https:' + illustMain.fluid.src },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'apple-mobile-web-app-title', content: '디자인 스펙트럼' },
            { name: 'apple-mobile-web-app-status-bar-style', content: 'black' }
          ]}
        >
          {/* <script src="https://cdn.bootpay.co.kr/js/bootpay-3.0.2.min.js" type="application/javascript"></script> */}
          <title>디자인 스펙트럼</title>
        </Helmet>
        <Layout>
        
          <IndexTop
            illustMain={illustMain}
            banner={banner}
          />

          <BlogPostList
            title={'all'}
            data={this.props.data.allContentfulBlogPost}
            // device={device}
          />

          <Divider />
          <EmailSubscription />
          <Divider />

        </Layout>

        <Footer />
      </div>
    );
  }
}

export default IndexPage

export const pageQuery = graphql`
  {
    allContentfulBlogPost (
    filter: {
      node_locale: {eq: "ko-KR"}
    },
    sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          activityType
          title
          speaker
          linkTo
          slug
          heroImage {
            fluid(maxWidth: 384, maxHeight: 384) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    },
    allContentfulAsset (
      filter: { title: {regex: "/illust-main|banner/"} }
    ) {
      edges {
        node {
          title
          fluid(maxWidth: 1334) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
